import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Social from '../../social/social';
import Sidebar from '../content/sidebar';
import Media from '../content/media';
import ByLine from '../content/by_line';
import Article from '../content/article';
import getByLineAttribution from '../../../../utils/by_line_util';
import withSecondScroll from './withSecondScroll';

/* eslint no-plusplus: 0 */
/* eslint no-restricted-syntax: 0 */
/* eslint no-return-assign: 0 */
/* eslint no-param-reassign: 0 */
/* eslint func-names: 0 */
/* eslint react/prop-types: 0 */

class ListArticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      social: true,
      openAccordion:
        this.props.posts[0].customFields.list_article.copy_display_style ===
          'accordion' &&
        this.props.posts[0].customFields.list_article.open_first_accordion
          ? 0
          : null,
      openPanel: 0,
      openMobileShare: false
    };

    this.accordionContent = [];

    // this.queerButton =
    //   'https://images.fastcompany.net/image/upload/v1587587567/fc/q50_button_km78sn.svg';
    // this.WCIarrow =
    //   'https://images.fastcompany.net/image/upload/v1587573587/fc/arrow_iq7i6n.svg';
    // this.WCIheader =
    //   'https://images.fastcompany.net/image/upload/v1587594921/fc/wci.top.art-12_ca7chy.svg';

    // Functions
    this.formattedListNumber = this.formattedListNumber.bind(this);
    this.handleAccordion = this.handleAccordion.bind(this);
    this.handlePanels = this.handlePanels.bind(this);
    this.toggleMobileShare = this.toggleMobileShare.bind(this);
    this.getDistance = this.getDistance.bind(this);

    // Refs
    this.panels = React.createRef();
  }

  getDistance(elem) {
    let location = 0;

    if (elem.offsetParent) {
      do {
        location += elem.offsetTop;
        elem = elem.offsetParent;
      } while (elem);
    }

    return location >= 0 ? location : 0;
  }

  formattedListNumber(options, number) {
    const i = number + 1;

    switch (options) {
      case 'one':
        return i;
      case 'two':
        return number < 9 ? `0${i}` : i;
      case 'one_hash':
        return `#${i}`;
      case 'two_hash':
        return number < 10 ? `#0${i}` : `#${i}`;
      case 'one_no':
        return `No. ${i}`;
      case 'two_no':
        return number < 10 ? `No. 0${i}` : `No. ${i}`;
      default:
        return i;
    }
  }

  handleAccordion(i) {
    this.setState({ openAccordion: this.state.openAccordion !== i ? i : null });
  }

  handlePanels(i) {
    this.setState({ openPanel: i }, function() {
      window.scrollTo(0, this.getDistance(this.panels.current) - 120);
    });
  }

  toggleMobileShare() {
    this.setState({
      openMobileShare: !this.state.openMobileShare
    });
  }

  render() {
    const { advertorial, fullPageAdvertorial } = this.props;

    const post = get(this.props.posts, '[0]', {});
    const listSettings = get(post, 'customFields.list_article');
    const entryData = get(post, 'customFields.list_article.entries');
    const secondaryEntryData = get(
      post,
      'customFields.list_article.secondary_entries'
    );
    const bylineAttribution = getByLineAttribution(this.props.posts[0].tags);
    const entries = [];
    const entries2 = [];
    let intro = [];
    let count1 = 0;
    let count2 = 0;

    // Clean up the entry data.
    for (const key in entryData) {
      if (
        count1 < post.customFields.list_article.number_of_entries &&
        key.includes('entry')
      ) {
        entries.push(entryData[key]);
        count1++;
      }
    }

    for (const key in secondaryEntryData) {
      if (
        count2 < post.customFields.list_article.number_of_entries_panel_2 &&
        key.includes('entry')
      ) {
        entries2.push(secondaryEntryData[key]);
        count2++;
      }
    }

    // Combine the article content into one text chunk.
    for (let x = 0; x < post.content.length; x++) {
      for (let y = 0; y < post.content[x].length; y++) {
        intro.push(post.content[x][y]);
      }
    }

    intro = intro.join('<!-- paragraph -->').replace(/<!-- paragraph -->/g, '');

    const primaryPanelEntries = (
      <section className="list">
        {entries.map((entry, idx) => {
          const extraPadding = !entry.name ? 'extra-margin' : '';

          return (
            <div className="list-entry panel1" key={`entry-${idx}`}>
              {entry.image && entry.image.sizes ? (
                <img
                  src={entry.image.sizes.medium}
                  className="entry-image"
                  alt=""
                />
              ) : (
                <div style={{ width: '100%', height: '10px' }} />
              )}

              {listSettings.numbered_list ? (
                <div className="entry-count">
                  {this.formattedListNumber(listSettings.number_style, idx)}
                </div>
              ) : (
                entry.category && (
                  <div className="entry-category">{entry.category}</div>
                )
              )}

              {entry.name && (
                <div
                  className="entry-name"
                  dangerouslySetInnerHTML={{ __html: entry.name }}
                />
              )}

              {entry.deck && (
                <div
                  className={`entry-deck ${extraPadding}`}
                  dangerouslySetInnerHTML={{ __html: entry.deck }}
                />
              )}

              {listSettings.copy_display_style === 'display'
                ? entry.copy && (
                    <div className="entry-copy">
                      <div dangerouslySetInnerHTML={{ __html: entry.copy }} />

                      {listSettings.standard_article_header &&
                        entry.link && (
                          <a
                            href={entry.link}
                            className="entry-link-button generic"
                            target="_blank"
                          >
                            {listSettings.link_button_text
                              ? listSettings.link_button_text
                              : 'More'}
                          </a>
                        )}
                    </div>
                  )
                : entry.copy && (
                    <div
                      className="accordion"
                      style={{
                        height:
                          this.accordionContent[idx] &&
                          this.state.openAccordion === idx
                            ? this.accordionContent[idx].offsetHeight + 25
                            : 0
                      }}
                    >
                      <div
                        className="entry-content"
                        ref={accordionContent =>
                          (this.accordionContent[idx] = accordionContent)
                        }
                      >
                        <div
                          className="entry-copy"
                          dangerouslySetInnerHTML={{ __html: entry.copy }}
                        />
                        {entry.link && (
                          <a
                            href={entry.link}
                            className="entry-link-button"
                            target="_blank"
                          >
                            {listSettings.link_button_text
                              ? listSettings.link_button_text
                              : 'More'}
                          </a>
                        )}
                      </div>
                    </div>
                  )}

              {listSettings.copy_display_style === 'display'
                ? entry.link && (
                    <div className="entry-button">
                      <img src={listSettings.button_image} alt="" />
                    </div>
                  )
                : entry.copy && (
                    <div className="entry-button">
                      <button
                        className={
                          this.state.openAccordion === idx ? 'open' : 'closed'
                        }
                        onClick={() => {
                          this.handleAccordion(idx);
                        }}
                      >
                        <img src={listSettings.button_image} alt="" />
                      </button>
                    </div>
                  )}

              {listSettings.copy_display_style === 'display' &&
                !listSettings.standard_article_header &&
                entry.link && (
                  <a
                    href={entry.link}
                    className="full-entry-link"
                    target="_blank"
                  >
                    {' '}
                  </a>
                )}
            </div>
          );
        })}
      </section>
    );

    const secondaryPanelEntries = (
      <section className="list">
        {entries2.map((entry, idx) => (
          <div className="list-entry panel2" key={`entry-${idx}`}>
            {entry.image && entry.image.sizes ? (
              <img
                src={entry.image.sizes.medium}
                className="entry-image"
                alt=""
              />
            ) : (
              <div style={{ width: '100%', height: '10px' }} />
            )}

            {listSettings.numbered_list ? (
              <div className="entry-count">
                {this.formattedListNumber(listSettings.number_style, idx)}
              </div>
            ) : (
              entry.category && (
                <div className="entry-category">{entry.category}</div>
              )
            )}

            {entry.name && <div className="entry-name">{entry.name}</div>}

            {entry.deck && <div className="entry-deck">{entry.deck}</div>}

            {listSettings.copy_display_style === 'display'
              ? entry.copy && (
                  <React.Fragment>
                    <div
                      className="entry-copy"
                      dangerouslySetInnerHTML={{ __html: entry.copy }}
                    />
                    {entry.link &&
                      listSettings.show_more && (
                        <a
                          href={entry.link}
                          className="entry-link-button"
                          target="_blank"
                        >
                          More
                        </a>
                      )}
                  </React.Fragment>
                )
              : entry.copy && (
                  <div
                    className="accordion"
                    style={{
                      height:
                        this.accordionContent[idx] &&
                        this.state.openAccordion === idx
                          ? this.accordionContent[idx].offsetHeight + 25
                          : 0
                    }}
                  >
                    <div
                      className="entry-content"
                      ref={accordionContent =>
                        (this.accordionContent[idx] = accordionContent)
                      }
                    >
                      <div
                        className="entry-copy"
                        dangerouslySetInnerHTML={{ __html: entry.copy }}
                      />
                      {entry.link &&
                        listSettings.show_more && (
                          <a
                            href={entry.link}
                            className="entry-link-button"
                            target="_blank"
                          >
                            More
                          </a>
                        )}
                    </div>
                  </div>
                )}

            {listSettings.copy_display_style === 'display'
              ? entry.link && (
                  <div className="entry-button">
                    <img src={listSettings.button_image} alt="" />
                  </div>
                )
              : entry.link && (
                  <div className="entry-button">
                    <button
                      className={
                        this.state.openAccordion === idx ? 'open' : 'closed'
                      }
                      onClick={() => {
                        this.handleAccordion(idx);
                      }}
                    >
                      <img src={listSettings.button_image} alt="" />
                    </button>
                  </div>
                )}

            {listSettings.copy_display_style === 'display' &&
              !listSettings.standard_article_header &&
              entry.link && (
                <a
                  href={entry.link}
                  className="full-entry-link"
                  target="_blank"
                >
                  {' '}
                </a>
              )}
          </div>
        ))}
      </section>
    );

    return (
      <React.Fragment>
        {!listSettings.standard_article_header ? (
          <header className="list-header">
            {listSettings.header_image_desktop && (
              <object
                class="headerDesktop"
                type="image/svg+xml"
                data={listSettings.header_image_desktop}
                aria-label={post.title}
              />
            )}
            {listSettings.header_image_mobile && (
              <object
                class="headerMobile"
                type="image/svg+xml"
                data={listSettings.header_image_mobile}
                aria-label={post.title}
              />
            )}
            {get(post, 'id', 0) === 90623897 && (
              <div className="wci20221_sponsor_bar">
                <p>IN PARTNERSHIP WITH</p>
                <img
                  src="https://images.fastcompany.net/image/upload/v1619728078/fc/Genpact_horizontal_white_rgb_btvppw.svg"
                  alt="World Changing Ideas 2021 Sponsor Logo"
                />
              </div>
            )}
            <h2 className="deck">{listSettings.list_page_deck}</h2>
          </header>
        ) : (
          <header
            className="genericListArticle"
            style={{ maxWidth: '1240px', margin: '0 auto' }}
          >
            <div
              style={{
                textAlign: 'center',
                maxWidth: '820px',
                margin: '0 auto'
              }}
            >
              <ul className="eyebrow">
                <li className="eyebrow__item">
                  <time timedate={get(post, 'datePublished', null)}>
                    {get(post, 'dateFormatted').replace(/\./gi, '-')}
                  </time>
                </li>

                {get(post, 'tags[0].name', null) && (
                  <li className="eyebrow__item">
                    <a href={get(post, 'tags[0].slug', null)} target="_blank">
                      {get(post, 'tags[0].name', null)}
                    </a>
                  </li>
                )}
              </ul>

              <h1
                className="post__title"
                dangerouslySetInnerHTML={{ __html: get(post, 'title', null) }}
              />

              <h2
                className="post__deck"
                dangerouslySetInnerHTML={{ __html: get(post, 'excerpt', null) }}
              />
            </div>

            {get(post, 'featured_image.source', null) && (
              <Media
                config={get(this.props, 'config', {})}
                post={get(this.props, 'posts[0]', null)}
                isVideoPost={false}
                isFirstPost={get(this.props, 'isFirstPost', true)}
              />
            )}
          </header>
        )}

        <div className="post post--default list-article">
          <div className="post__main">
            <Social
              enableSticky={true}
              empty={false}
              topValue={fullPageAdvertorial ? 160 : 111}
              text={encodeURIComponent(get(post, 'title') || '')}
              uri={post.isNewUrl ? get(post, 'newUrl', '') : post.link}
              modifier="post"
              bottomBoundary={`#social--${post.id}`}
              encapsulateId={`social--${post.id}`}
              active={this.state.social}
              {...this.props}
            />

            <div className={`post__container post__container--${post.layout}`}>
              <div className="post__content">
                {!listSettings.standard_article_header ? (
                  <section className="introduction">
                    {get(post, 'id', 0) === 90623897 && (
                      <img
                        className="wci2021Logo"
                        src="https://images.fastcompany.net/image/upload/v1619728079/fc/WCI_FC_logo_small_black_cc5yae.svg"
                        alt="World Changing Ideas 2021 Logo"
                      />
                    )}
                    <span dangerouslySetInnerHTML={{ __html: intro }} />
                  </section>
                ) : (
                  <React.Fragment>
                    <ByLine
                      post={get(this.props, 'posts[0]', null)}
                      bylineAttribution={get(bylineAttribution, '', null)}
                      live={false}
                    />

                    <Article
                      config={get(this.props, 'config', {})}
                      advertorial={get(advertorial, '', false)}
                      post={get(this.props, 'posts[0]', null)}
                      pageNum={0}
                      isFirstPost={get(this.props, 'isFirstPost', false)}
                      isShortPost={false}
                    />
                  </React.Fragment>
                )}

                {listSettings.use_two_panels ? (
                  <React.Fragment>
                    <div
                      className={`panel-header ${
                        this.state.openMobileShare ? 'active' : ''
                      }`}
                    >
                      <div
                        className={`tab tab1 ${
                          this.state.openPanel === 0 ? 'active' : ''
                        }`}
                        onClick={() => {
                          this.handlePanels(0);
                        }}
                      >
                        {' '}
                        {listSettings.primary_tab_label}{' '}
                      </div>
                      <div
                        className={`tab tab2 ${
                          this.state.openPanel === 1 ? 'active' : ''
                        }`}
                        onClick={() => {
                          this.handlePanels(1);
                        }}
                      >
                        {' '}
                        {listSettings.secondary_tab_label}{' '}
                      </div>
                      <div
                        className={`tab mobile-share ${
                          this.state.openPanel === 2 ? 'active' : ''
                        }`}
                        onClick={() => {
                          this.toggleMobileShare();
                        }}
                      >
                        <img
                          src="https://images.fastcompany.net/image/upload/v1587762424/fc/share_icon_k1ift6.svg"
                          alt="share"
                        />
                      </div>
                      <Social
                        enableSticky={false}
                        empty={false}
                        topValue={fullPageAdvertorial ? 160 : 111}
                        text={encodeURIComponent(get(post, 'title') || '')}
                        uri={
                          post.isNewUrl ? get(post, 'newUrl', '') : post.link
                        }
                        modifier="post"
                        bottomBoundary={`#social--${post.id}`}
                        encapsulateId={`social--${post.id}`}
                        active={this.state.social}
                        {...this.props}
                      />
                    </div>

                    <section className="panels" ref={this.panels}>
                      <div
                        className={`panel ${
                          this.state.openPanel === 0 ? 'active' : ''
                        }`}
                      >
                        {primaryPanelEntries}
                      </div>
                      <div
                        className={`panel ${
                          this.state.openPanel === 1 ? 'active' : ''
                        }`}
                      >
                        {secondaryPanelEntries}
                      </div>
                    </section>
                  </React.Fragment>
                ) : (
                  <section className="list-container">
                    {primaryPanelEntries}
                  </section>
                )}
              </div>
            </div>
          </div>

          <Sidebar
            {...this.props}
            post={post}
            fullPageAdvertorial={advertorial}
            isLongPost={true}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withSecondScroll(ListArticle);

ListArticle.defaultProps = {
  fullPageAdvertorial: true
};

ListArticle.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      customFields: PropTypes.shape({
        list_article: PropTypes.shape({
          copy_display_style: PropTypes.string,
          open_first_accordion: PropTypes.bool
        })
      })
    })
  ).isRequired,
  fullPageAdvertorial: PropTypes.bool,
  advertorial: PropTypes.bool.isRequired
};
