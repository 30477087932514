import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

export default class GiftFilter extends Component {
  constructor(props) {
    super(props);

    this.handleSortOnChange = this.handleSortOnChange.bind(this);
  }

  handleInputOnChange(active, filterItemKey, selected) {
    if (active) {
      this.props.handleToggleFilterItem('category', filterItemKey, selected);
    }
  }

  handleSortOnChange(event) {
    const [sortBy, sortAsc] = event.target.value.split(',');
    this.props.handleSort(sortBy, sortAsc === 'true');
  }

  render() {
    const list = get(this.props, 'filters.category.list', []);

    const filters = Object.entries(list).map(
      ([filterItemKey, { name, active, selected }]) => (
        <div
          className="gift-filter__categories__category"
          key={filterItemKey}
          htmlFor={filterItemKey}
        >
          <input
            type="checkbox"
            name={filterItemKey}
            checked={selected}
            onChange={() =>
              this.handleInputOnChange(active, filterItemKey, !selected)
            }
          />
          <span>{name}</span>
        </div>
      )
    );

    const allCategories = filters.shift();

    const sort = (
      <div className="gift-dropdown">
        <select onChange={this.handleSortOnChange}>
          <option value="">DEFAULT</option>
          <option value="name,true">PRODUCT NAME</option>
          <option value="price,false">PRICE HIGHEST TO LOWEST</option>
          <option value="price,true">PRICE LOWEST TO HIGHEST</option>
        </select>
      </div>
    );

    return (
      <div className="gift-filter">
        <div className="gift-filter__sortby">
          <div className="gift-filter__sortby__label">SORT BY</div>
          {sort}
        </div>
        <div className="gift-filter__categories">
          <div className="border_left" />
          <div className="gift-filter__categories__all">{allCategories}</div>
          <div className="gift-filter__categories__individual">{filters}</div>
        </div>
      </div>
    );
  }
}

GiftFilter.propTypes = {
  handleToggleFilterItem: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired
};
