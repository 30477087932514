/* eslint-disable no-param-reassign */
import isEmpty from 'lodash/isEmpty';

const getAllGifts = list => {
  let tempArr = [];

  Object.keys(list).forEach(category => {
    if (list[category]) tempArr = [...tempArr, ...list[category]];
  });

  return tempArr;
};

const generateGifts = (giftGuide, filters, sortBy, sortAsc) => {
  const formattedList = {};

  Object.keys(giftGuide).forEach(entry => {
    if (!isEmpty(giftGuide[entry])) {
      const cat = giftGuide[entry].gift_category;
      const list = giftGuide[entry].entries;

      if (Array.isArray(list)) {
        list.forEach(gift => {
          if (gift.price && !Number.isNaN(Number(gift.price)))
            gift.price = parseFloat(gift.price);
        });

        formattedList[cat] = list;
      }
    }
  });

  let filteredGifts = [];
  const list = Object.values(filters.category.list);

  // If one or more filters are selected, filter out those gifts.
  // If not a single filter is selected, let all gifts through
  const selectedFilters =
    list.filter(({ selected }) => selected).reduce((acc, { name }) => {
      acc.push(name);
      return acc;
    }, []) || [];

  if (selectedFilters.length > 0) {
    for (let i = 0; i < selectedFilters.length; i += 1) {
      const filter = selectedFilters[i];

      if (filter === 'All Categories') {
        filteredGifts = getAllGifts(formattedList);
        break;
      } else if (formattedList[filter]) {
        filteredGifts = [...filteredGifts, ...formattedList[filter]];
      }
    }
  }

  if (sortBy) {
    filteredGifts = filteredGifts.sort((a, b) => {
      let compareA;
      let compareB;

      if (sortBy === 'price') {
        // remove all alphabet letters and commas
        const aVal = (a || [])[sortBy]
          .toString()
          .replace(/^\D+/g, '')
          .split(',')
          .join('');
        const bVal = (b || [])[sortBy]
          .toString()
          .replace(/^\D+/g, '')
          .split(',')
          .join('');

        compareA = parseInt(aVal || 0, 10);
        compareB = parseInt(bVal || 0, 10);
      } else {
        compareA = (a || [])[sortBy];
        compareB = (b || [])[sortBy];
      }

      if (compareA < compareB) {
        return -1;
      }
      if (compareA > compareB) {
        return 1;
      }
      return 0;
    });

    if (!sortAsc) {
      filteredGifts.reverse();
    }
  }

  // get unique items only because some can appeared in multiple categories
  // should not show up multiple times with their categories are selected
  const finalArr = filteredGifts.filter(
    (gift, index, self) =>
      index === self.findIndex(gift2 => gift.name === gift2.name)
  );

  return finalArr;
};

export default generateGifts;
