import React from 'react';
import PropTypes from 'prop-types';
import withSecondScroll from './withSecondScroll';

const ArticleRedesign = props => (
  <section className="article-container article_redesign">
    <div className="post post--default post--redesign">
      <article>{props.children}</article>
    </div>
  </section>
);

ArticleRedesign.propTypes = {
  children: PropTypes.node.isRequired
};

export default withSecondScroll(ArticleRedesign);
