import { canUseDOM } from 'exenv';
import set from 'lodash/set';
import franchisePageAction from '../../../../redux/actions/franchise/franchise_page_actions';
import log from '../../../../services/logger_service';

export default function fetchDataWithStore(store, override, customData) {
  return function fetchData(nextState, replace, callback) {
    const { dispatch } = store;

    if (override) {
      set(nextState, 'params.year', override);
    }

    if (customData && customData.type) {
      set(nextState, 'params.type', customData.type);
    }

    // run async
    if (canUseDOM) {
      if (store.getState().config.initialPageLoad === true) {
        if (callback) {
          return callback();
        }
        return false;
      }
      franchisePageAction(nextState.params, store)(dispatch);
      if (callback) {
        return callback();
      }
      return false;
    }
    // run sync
    return franchisePageAction(nextState.params, store)(dispatch)
      .then(function cb() {
        if (callback) {
          return callback();
        }
        return false;
      })
      .catch(function handleActionError(error) {
        log.fatal(
          error,
          `Fatal error in onEnter state manager: ${error.message}`
        );
        if (callback) {
          return callback(error);
        }
        return false;
      });
  };
}
