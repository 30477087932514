import isEmpty from 'lodash/isEmpty';
import { slugify } from './string_util';

const filters = {
  category: {
    label: 'Categories',
    list: {
      'all-categories': {
        name: 'All Categories',
        selected: true,
        active: true
      }
    }
  }
};

const generateFilters = activeGifts => {
  Object.keys(activeGifts).forEach(entry => {
    if (!isEmpty(activeGifts[entry])) {
      const giftCategory = slugify(activeGifts[entry].gift_category);

      if (giftCategory) {
        filters.category.list[giftCategory] = {
          name: activeGifts[entry].gift_category,
          selected: false,
          active: true
        };
      }
    }
  });

  const filterData = filters.category;
  const sortedKeys = Object.keys(filterData.list).sort();
  const sortedFilters = {};
  sortedKeys.forEach(k => {
    sortedFilters[k] = filterData.list[k];
  });
  filters.category.list = sortedFilters;

  return filters;
};

export default generateFilters;
