import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Header from '../content/header';
import withSecondScroll from './withSecondScroll';

const VerticalPoster = props => {
  const { posts, children } = props;
  const post = get(posts, '[0]', {});
  post.layout = 'vertical'; // So we don't render a 2nd header when inside the Body component

  return (
    <Fragment>
      <div className="post__hero post__vertical__poster">
        <Header
          {...props}
          tags={post.tags}
          post={post}
          isLongPost={true}
          insert={false}
        />
      </div>
      {children}
    </Fragment>
  );
};

VerticalPoster.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  advertorial: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default withSecondScroll(VerticalPoster);
