import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Card from '../../card/card';

export default function GiftGrid(props) {
  const gifts = get(props, 'gifts', []);

  if (isEmpty(gifts)) return null;

  const list = gifts.map(gift => {
    if (gift.name) return <Card type="gift" metadata={gift} />;
    return null;
  });

  return <div className="gift-grid">{list}</div>;
}
