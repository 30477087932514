/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
// import Body from '../content/body';
import Header from '../content/header';
import Filter from '../content/gift_filter';
import GiftGrid from '../content/gift_grid';
import generateFilters from '../../../../utils/generate_gift_filters_util';
import generateGifts from '../../../../utils/generate_gifts_util';
import Social from '../../social/social';
import withSecondScroll from './withSecondScroll';

class GiftGuide extends Component {
  constructor(props) {
    super(props);
    const post = get(props.posts, '[0]', {});
    this.giftGuide = get(post, 'customFields.gift_guide', {});

    this.state = {
      filters: generateFilters(this.giftGuide),
      sortBy: '',
      sortAsc: true
    };

    this.handleToggleFilter = this.handleToggleFilter.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  handleClearAllFilters() {
    const newFilters = this.state.filters;

    Object.values(newFilters).forEach(filter => {
      Object.values(filter.list).forEach(filterItem => {
        filterItem.selected = false;
      });
    });

    newFilters.category.list['all-categories'].selected = true;

    this.setState({
      filters: newFilters
    });
  }

  handleToggleFilter(parentKey, filterKey, toggle) {
    const newFilters = this.state.filters;
    newFilters[parentKey].list[filterKey].selected = toggle;

    if (filterKey !== 'all-categories') {
      newFilters[parentKey].list['all-categories'].selected = false;
    } else {
      this.handleClearAllFilters();
    }

    this.setState({
      filters: newFilters
    });
  }

  handleSort(sortBy, sortAsc) {
    this.setState({
      sortBy,
      sortAsc
    });
  }

  render() {
    const post = get(this.props.posts, '[0]', {});
    const gifts = generateGifts(
      this.giftGuide,
      this.state.filters,
      this.state.sortBy,
      this.state.sortAsc
    );
    const content = get(post, 'content', []);
    const imgCredits = gifts
      .map(gift => get(gift, 'image_upload.caption', ''))
      .filter(credit => credit !== '');
    const photoCourtesy = get(this.giftGuide, 'photo_courtesy', false);

    return (
      <React.Fragment>
        <div className="post__gift">
          <Header
            {...this.props}
            tags={post.tags}
            post={post}
            isLongPost={true}
          />

          <div className="post__gift__container">
            <div className="post__gift__container__social">
              <Social
                enableSticky={true}
                empty={false}
                topValue={100}
                text={encodeURIComponent(get(post, 'title') || '')}
                uri={post.isNewUrl ? get(post, 'newUrl', '') : post.link}
                bottomBoundary={`#social--${post.id}`}
                modifier="gift-2020-desktop"
                encapsulateId={`social--${post.id}`}
                active={this.state.social}
                {...this.props}
              />
              <Social
                enableSticky={true}
                empty={false}
                topValue={100}
                text={encodeURIComponent(get(post, 'title') || '')}
                modifier="gift-2020-mobile"
                uri={post.isNewUrl ? get(post, 'newUrl', '') : post.link}
                bottomBoundary={`#social--${post.id}`}
                encapsulateId={`social--${post.id}`}
                active={this.state.social}
                {...this.props}
              />
            </div>
            <div className="post__gift__container__body">
              <h2 className="desc">
                {content.map(cont =>
                  cont.map(text => (
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                  ))
                )}
              </h2>
              <Filter
                filters={this.state.filters}
                handleToggleFilterItem={this.handleToggleFilter}
                handleSort={this.handleSort}
              />
              <GiftGrid gifts={gifts} />
              {Array.isArray(imgCredits) &&
                imgCredits.length > 0 && (
                  <p className="gift-credits">
                    {photoCourtesy ? 'Photos courtesy: ' : 'Photos: '}
                    {imgCredits.join('; ')}
                  </p>
                )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

GiftGuide.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  // advertorial: PropTypes.bool.isRequired
};

export default withSecondScroll(GiftGuide);
