import React from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import set from 'lodash/set';
import Body from '../content/body';
import Header from '../content/header';
import { isAdvertorial } from '../../advertorial/advertorial';
import { isCategory } from '../../../../utils/category_util';
import withSecondScroll from './withSecondScroll';

const PhotoEssay = props => {
  const { posts, advertorial } = props;
  const post = get(posts, '[0]', {});
  set(post, 'layout', 'hero');

  const photoEssaySections = get(post, 'customFields.photo_essay_sections', {});
  const photoEssayAdFrequency = get(post, 'customFields.ad_frequency', {});

  const isAdvertorialPost = isAdvertorial(get(post, 'tags'));
  const postCategories = get(post, 'categories', []);
  const isRecommender = isCategory(postCategories, 'recommender');

  const abGroupCookie = Cookies.get('abgroup');
  let defaultABGroup = 0;
  if (abGroupCookie) {
    defaultABGroup = parseInt(abGroupCookie, 10);
  }
  let canRenderInfiniteScroll = false;
  if (isAdvertorialPost || isRecommender || defaultABGroup > 10) {
    canRenderInfiniteScroll = true;
  }

  return [
    <div className="post__photoessay">
      <Header {...props} tags={post.tags} post={post} isLongPost={true} />
    </div>,
    <div className="post--photoessay">
      <div className="post__main">
        <Body
          {...props}
          post={post}
          canRenderInfiniteScroll={canRenderInfiniteScroll}
          isLongPost={true}
          hasNewsletter={true}
          fullPageAdvertorial={advertorial}
          photoEssaySections={photoEssaySections}
          photoEssayAdFrequency={photoEssayAdFrequency}
        />
      </div>
    </div>
  ];
};

PhotoEssay.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  // advertorial: PropTypes.bool.isRequired
};

export default withSecondScroll(PhotoEssay);
