import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Sidebar from '../content/sidebar';
import Media from '../content/media';
import Title from '../content/title';
import Social from '../../social/social';
import RelatedSlider from '../../related_slider/related_slider';

const PostVideo = props => {
  const { config, related, tags, posts, isFirstPost, advertorial } = props;
  const post = get(posts, '[0]', {});

  return (
    <div className="post post--video">
      <div className="post__main">
        <div className="post__body">
          <div className="post__container post__container--short">
            <Social
              text={encodeURIComponent(get(post, 'title') || '')}
              uri={post.link}
              modifier="post"
              bottomBoundary={`#social--${post.id}`}
              encapsulateId={`social--${post.id}`}
              {...props}
            />
            <div className="post__content">
              <Media
                config={config}
                post={post}
                isVideoPost={true}
                isFirstPost={isFirstPost}
              />
              <Title
                config={config}
                isFirstPost={isFirstPost}
                post={post}
                tags={tags}
                live={false}
              />
              <div
                className="post__article"
                dangerouslySetInnerHTML={{ __html: post.excerpt }}
              />
              <RelatedSlider
                title="Featured Videos"
                related={related}
                config={config}
                slideClassNames={['video-slide--horizontal']}
              />
            </div>
          </div>
        </div>
      </div>
      <Sidebar
        {...props}
        post={post}
        fullPageAdvertorial={advertorial}
        isVideoPost={true}
      />
    </div>
  );
};

PostVideo.defaultProps = {
  related: ''
};

PostVideo.propTypes = {
  config: PropTypes.shape({}).isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  related: PropTypes.arrayOf(PropTypes.shape({})),
  isFirstPost: PropTypes.bool.isRequired,
  pageNum: PropTypes.number.isRequired,
  advertorial: PropTypes.bool.isRequired
};

export default PostVideo;
