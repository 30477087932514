import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import withSecondScroll from './withSecondScroll';
import { isStartRunGrow } from '../../advertorial/advertorial';

import loadable from '@loadable/component';

const Body = loadable(() => import('../content/body'));
const Sidebar = loadable(() => import('../content/sidebar'));
const ArticleDisclaimer = loadable(() => import('../../fc_executive_board'), {
  resolveComponent: components => components.ArticleDisclaimer
});
const SponsorBar = loadable(() => import('../content/sponsor_bar'));

const PostDefault = props => {
  const { posts, advertorial } = props;
  const post = get(posts, '[0]', {});
  const tags = Array.from(post.tags, tag => tag.slug);
  const hasFCEB = tags.includes('fast-company-executive-board');
  const showSponsorBar = isStartRunGrow(post.tags);

  return (
    <Fragment>
      {hasFCEB && <ArticleDisclaimer />}
      {showSponsorBar && (
        <SponsorBar>
          <div className="tag-sponsor-container">
            <span className="tag-sponsor-label">PRESENTED BY</span>
            <div className="tag-sponsor-logo">
              <a href="#">
                <img
                  alt=""
                  src="https://wp-cms-fastcompany-com.s3.amazonaws.com/uploads/2023/07/GP_logo_strapline_crop.png"
                  width={150}
                  height={62}
                />
              </a>
            </div>
          </div>
        </SponsorBar>
      )}
      <div className="post post--default">
        <div className="post__main">
          <Body
            {...props}
            post={post}
            isLongPost={true}
            hasNewsletter={true}
            fullPageAdvertorial={advertorial}
            hasFCEB={hasFCEB}
            specialRedesignArticle={props.specialRedesignArticle}
          />
        </div>
        <Sidebar
          {...props}
          post={get(posts, '[0]')}
          fullPageAdvertorial={advertorial}
          isLongPost={true}
        />
      </div>

      {hasFCEB && <div id="pressboard-ad-sponsorship-msg" />}
    </Fragment>
  );
};

PostDefault.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  advertorial: PropTypes.bool.isRequired,
  specialRedesignArticle: PropTypes.bool.isRequired
};

export default withSecondScroll(PostDefault);
