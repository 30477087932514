/* eslint-disable no-return-assign, no-param-reassign, no-unused-vars */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, assign } from 'lodash';
import Media from '../content/media';
import MobileVideoPlayer from '../../mobile_video_module/mobile_video_module_reactjw';
import Title from '../content/title';
import { imageSizesLookup } from '../../../../utils/cloudinary_asset_util';
import Sticky from 'react-stickynode';
import Recaptcha from '../../newsletters/recaptcha';
import RelatedPosts from '../../related_posts/related_posts';
import axios from 'axios';
import { canUseDOM } from 'exenv';
import AdContainer from '../../ad/AdContainer';

const EventVideo = props => {
  const { config, tags, posts, isFirstPost, advertorial, event } = props;

  const post = get(posts, '[0]', {});
  const emailRef = useRef();

  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [isMobile, setIsMobile] = useState(null);
  const [stickyEnabled, setStickyEnabled] = useState(true);
  const [relatedVideos, setRelatedVideos] = useState(null);
  const [relatedContent, setRelatedContent] = useState(null);

  const getRelatedVideos = playlist => {
    axios
      .get(`//fc-api.fastcompany.com/api/v1/jw-playlist/${playlist}/1`)
      .then(res => {
        res.data.playlist.map(video => (video.categories = null));

        setRelatedVideos(
          res.data.playlist
            .filter(video => video.id !== post.posterVideo.feedId)
            .slice(0, 3)
        );
      });
  };

  const getRelatedContent = tag => {
    axios
      .get(
        `//fc-api.fastcompany.com/api/v2/posts-tag-slugs/fastcompany/${tag}?withBody=0`
      )
      .then(res => {
        setRelatedContent(
          res.data.posts.filter(article => article.id !== post.id).slice(0, 12)
        );
      });
  };

  const hideMobileVideoPlayer = () => {
    setStickyEnabled(false);
  };

  const determineMobile = e => {
    setIsMobile(e.matches);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const actualListId = 613;
    const savedEmail = emailRef.current.value;
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const emailStatus = emailRegex.test(savedEmail);

    const data = {
      email: savedEmail,
      listId: actualListId,
      first_name: 'new',
      last_name: 'user',
      double_opt_in: false
    };

    if (emailStatus && captcha !== undefined) {
      const webApiUrl = 'https://tempo.inc.com/getrevue/add/';
      const tokenStr = 'iHKTPKgaDUSYFKnbsZlEnbPT119pM-3e';

      axios
        .post(webApiUrl, data, {
          headers: {
            Authorization: `Token ${tokenStr}`
          }
        })
        .then(response => {
          if (response.status === 200) {
            setSubscribed(true);
            // Adam if you want to display result stuff you can do it here
            // WARNING: You can't call two separate set state hooks next to eachother, the state won't update reliably
            // If you need to do that you will need to make one state variable and just update it like this:
            // setState({
            //   ...state,
            //   new info
            // })

            setShowCaptcha(false);
            // console.log('Emeail success');
            // this.setState({
            //   disabled: true,
            //   status: "You're Now Subscribed!",
            //   email: "You're Now Subscribed!",
            //   shouldDisplayReCaptcha: false
            // });
          }
        })
        .catch(err => {
          console.warn('AXIOS ERROR: ', err.response); // eslint-disable-line no-console
          // this.setState({
          //   disabled: false,
          //   status: err.response.data.error.email[0]
          // });
        });
    } else {
      // console.log("Email didn't succeed");
      // this.setState({ disabled: false, status: 'Please Try Again'});
    }
  };

  const handleInputFocus = () => {
    // console.log('Called');
    if (!showCaptcha) {
      setShowCaptcha(true);
    }
  };

  const handleVerify = value => {
    // console.log('verify called');
    setCaptcha(value);
  };

  useEffect(() => {
    const mql = canUseDOM && window.matchMedia('(max-width: 639px)');
    mql.addEventListener('change', determineMobile);
    setIsMobile(canUseDOM && window.innerWidth < 640);
    getRelatedVideos('ab9of6Uh');
    getRelatedContent('innovation-festival-360');
  }, []);

  switch (event) {
    case 'innovation-festival-360': {
      const [speakersOpen, setSpeakersOpen] = useState(false);
      const [moderatorsOpen, setModeratorsOpen] = useState(false);

      const goToTag = tag => {
        window.open(`/section/${tag.slug}`);
      };

      const videoPlayer = isMobile ? (
        <Sticky
          enabled={stickyEnabled}
          innerZ={stickyEnabled ? '10000000' : '1'}
        >
          <MobileVideoPlayer
            mobileInline={true}
            type="feature"
            playerid={post.playerScriptId}
            playlistId={post.posterVideo.feedId}
            initialTitle={post.title}
            pos="newmultiplayer"
            noSlug
            hideMobileVideoPlayer={hideMobileVideoPlayer}
          />
        </Sticky>
      ) : (
        <Media
          config={config}
          post={post}
          isVideoPost={true}
          isFirstPost={isFirstPost}
        />
      );

      return (
        <div className="event-video if360">
          <div className="top-section">
            <div className="container">
              <section className="logo-container">
                <div className="package-logo">
                  <img
                    src="//images.fastcompany.net/image/upload/v1636582865/fcweb/if360-logo-small_mwdnkq.svg"
                    alt="IF360"
                  />
                </div>
              </section>

              <section className="video-player">{videoPlayer}</section>

              <section className="header">
                <div className="hed">
                  <div className="pubdate" />

                  <div className="video-hed">
                    <Title
                      config={config}
                      isFirstPost={isFirstPost}
                      post={post}
                      tags={tags}
                      live={false}
                    />
                  </div>
                </div>

                {tags && (
                  <div className="tags">
                    {tags.map((tag, i) => {
                      if (!tag.slug.includes('byline')) {
                        return (
                          <div
                            className="tag"
                            key={`tag-${i}`}
                            onClick={() => goToTag(tag)}
                          >
                            {tag.name}
                          </div>
                        );
                      }
                      return false;
                    })}
                  </div>
                )}
              </section>
            </div>
          </div>

          <div className="content">
            <div className="container">
              <div className="main">
                <div className="intro">
                  <h3>Description</h3>

                  <div
                    className="copy"
                    dangerouslySetInnerHTML={{ __html: post.excerpt }}
                  />
                </div>

                {post.customFields.speakers && (
                  <div
                    className={`speakers ${speakersOpen ? 'open' : 'closed'}`}
                    onClick={() => setSpeakersOpen(!speakersOpen)}
                  >
                    <h3>
                      Speaker{post.customFields.speakers.length > 1 ? 's' : ''}
                      <span className="mobileToggle" />
                    </h3>

                    <div className="copy">
                      {post.customFields.speakers.map(speaker => (
                        <div className="entry">
                          <div className="info">
                            <div className="image">
                              <img src={speaker.image} alt={speaker.name} />
                            </div>

                            <div className="details">
                              {speaker.name && (
                                <div className="name">{speaker.name}</div>
                              )}
                              {speaker.title && (
                                <div className="title">
                                  {speaker.title}
                                  {speaker.company && ','}
                                </div>
                              )}
                              {speaker.company && (
                                <div className="company">{speaker.company}</div>
                              )}
                            </div>
                          </div>

                          <div className="description">
                            {speaker.description}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {post.customFields.moderators && (
                  <div
                    className={`moderator ${
                      moderatorsOpen ? 'open' : 'closed'
                    }`}
                    onClick={() => {
                      setModeratorsOpen(!moderatorsOpen);
                    }}
                  >
                    <h3>
                      Moderator{post.customFields.moderators.length > 1
                        ? 's'
                        : ''}
                      <span className="mobileToggle" />
                    </h3>

                    <div className="copy">
                      {post.customFields.moderators.map(moderator => (
                        <div className="entry">
                          <div className="info">
                            <div className="image">
                              <img src={moderator.image} alt={moderator.name} />
                            </div>

                            <div className="details">
                              {moderator.name && (
                                <div className="name">{moderator.name}</div>
                              )}
                              {moderator.title && (
                                <div className="title">
                                  {moderator.title}
                                  {moderator.company && ','}
                                </div>
                              )}
                              {moderator.company && (
                                <div className="company">
                                  {moderator.company}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="description">
                            {moderator.description}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="registration">
                  <div className="logo">
                    <img
                      src="//images.fastcompany.net/image/upload/v1636582865/fcweb/if360-logo-small_mwdnkq.svg"
                      alt="IF360"
                    />
                  </div>

                  <div className="signup">
                    <div className="copy">
                      <div className="hed">STAY UPDATED!</div>

                      <div className="dek">Get the Latest Events and News</div>
                    </div>

                    {!subscribed && (
                      <div className="form">
                        <input
                          type="email"
                          ref={emailRef}
                          onFocus={() => {
                            // console.log('in focus');
                            handleInputFocus();
                          }}
                          placeholder="YOUR EMAIL ADDRESS"
                        />

                        {canUseDOM &&
                        window.innerWidth < 1024 &&
                        showCaptcha ? (
                          <div className="captcha">
                            <Recaptcha
                              id="sponsoredvideo"
                              verifyCallback={handleVerify}
                            />
                          </div>
                        ) : (
                          ''
                        )}

                        <button onClick={handleSubmit}>SIGN UP</button>
                      </div>
                    )}

                    {subscribed && (
                      <div className="subscribed-message">
                        {`YOU'RE NOW SUBSCRIBED!`}
                      </div>
                    )}

                    {canUseDOM && window.innerWidth >= 1024 && showCaptcha ? (
                      <div className="captcha">
                        <Recaptcha
                          id="sponsoredvideo"
                          verifyCallback={handleVerify}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>

              <div className="sidebar">
                {relatedVideos && (
                  <RelatedPosts
                    slug="Watch Next"
                    config={config}
                    fullPageAdvertorial={advertorial}
                    stories={relatedVideos.map(article =>
                      assign(article, {
                        assetSizes: imageSizesLookup.column
                      })
                    )}
                    hideOnMobile
                    horizontal
                    reverse
                  />
                )}

                <AdContainer type="rail" />
              </div>
            </div>
          </div>

          <AdContainer type="break" />

          {/* <div className="content">
            <div className="container">
              {relatedContent && (
                <RelatedPosts
                  slug="Related Content"
                  slugClassName="event-video"
                  config={config}
                  stories={relatedContent.map(article =>
                    assign(article, {
                      assetSizes: imageSizesLookup.column
                    })
                  )}
                  fullPageAdvertorial={false}
                  tiled
                />
              )}
            </div>
          </div> */}
        </div>
      );
    }

    default: {
      return 'video page';
    }
  }
};

EventVideo.defaultProps = {
  related: {}
};

EventVideo.propTypes = {
  config: PropTypes.shape({}).isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  related: PropTypes.arrayOf(PropTypes.shape({})),
  isFirstPost: PropTypes.bool.isRequired,
  pageNum: PropTypes.number.isRequired,
  advertorial: PropTypes.bool.isRequired
};

export default EventVideo;
