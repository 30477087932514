import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import clsx from 'clsx';
import Body from '../content/body';
import Sidebar from '../content/sidebar';
import Header from '../content/header';
import { isPremium } from '../../../../views/components/advertorial/advertorial';
import withSecondScroll from './withSecondScroll';

const PostHero = props => {
  const { posts, advertorial } = props;
  const post = get(posts, '[0]', {});
  const isPremiumArticle = isPremium(get(post, 'tags'));

  return [
    <div className="post__hero">
      <Header
        {...props}
        tags={post.tags}
        post={post}
        isLongPost={true}
        insert={false}
      />
    </div>,
    <div className="post post--hero">
      <div
        className={clsx(
          'post__main',
          isPremiumArticle && 'post__main--premium'
        )}
      >
        <Body
          {...props}
          post={post}
          isLongPost={true}
          hasNewsletter={true}
          fullPageAdvertorial={advertorial}
        />
      </div>
      <Sidebar
        {...props}
        post={post}
        fullPageAdvertorial={advertorial}
        isLongPost={true}
      />
    </div>
  ];
};

PostHero.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  advertorial: PropTypes.bool.isRequired
};

export default withSecondScroll(PostHero);
