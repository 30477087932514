import React from 'react';
import withSecondScroll from './withSecondScroll';
import propTypes from 'prop-types';

const CerosEmbed = ({ embedCode }) => (
  <div className="post__body">
    <div dangerouslySetInnerHTML={{ __html: embedCode }} />
  </div>
);

CerosEmbed.propTypes = {
  embedCode: propTypes.string.isRequired
};

export default withSecondScroll(CerosEmbed);
