import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import withRouter from 'react-router/withRouter';
import loadData from '../franchise_page_stage_manager';
import { slugify } from '../../../../../utils/string_util';

import loadable from '@loadable/component';

const Section = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Section
  }
);
const Text = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Text
  }
);
const Image = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Image
  }
);
const Button = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Button
  }
);
const ArticleList = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.ArticleList
  }
);
const Grid = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Grid
  }
);
const Col = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Col
  }
);
const Row = loadable(
  () => import('../../../../fc-components/main/components'),
  {
    resolveComponent: components => components.Row
  }
);
const StickyBar = loadable(
  () => import('../../../../components/StickyBar/StickyBar'),
  {
    resolveComponent: components => components.StickyBar
  }
);
const IbdLayout = loadable(
  () => import('../../../../fc-components/main/containers/IbdLayout/IbdLayout'),
  {
    resolveComponent: components => components.IbdLayout
  }
);
const NotFound = loadable(() =>
  import('../../../../components/not_found_component/not_found_component')
);
const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);
const AdContainer = loadable(() =>
  import('../../../../components/ad/AdContainer')
);

const AmazonLayout = props => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [notDeskTop, setNotDeskTop] = useState(false);
  const fullPackageObj = get(props, 'franchisePage', null);
  const fullPackageData = get(fullPackageObj, 'data', {});
  const isAmazon = get(fullPackageData, 'slug') === 'amazon-unpacked';
  let sectionBgColor = '#000';
  let headerColor = '#fff';
  let textColor = '#ccc';
  let maxWidth = 800;
  let borderBottom = '#777';

  const franchiseTitle = get(fullPackageData, 'title.rendered', '');

  const franchiseHeroImageDesktop = get( //eslint-disable-line
    fullPackageData,
    'acf.franchise_images.franchise_hero_image_desktop',
    ''
  );
  const franchiseHeroImageMobile = get(//eslint-disable-line
    fullPackageData,
    'acf.franchise_images.franchise_hero_image_mobile',
    ''
  );
  const franchiseBlurb = get(fullPackageData, 'acf.franchise_blurb', '');
  const sections = get(
    fullPackageData,
    'acf.innovation_by_design.sections',
    []
  );

  const [heroImage, setHeroImage] = useState(franchiseHeroImageDesktop);

  const checkIfDesktop = x => {
    setNotDeskTop(x.matches);
  };

  // detect screen size on render, and watch for changes
  useEffect(() => {
    const x = window.matchMedia(`(max-width: 1024px)`);
    checkIfDesktop(x);

    x.addEventListener('change', checkIfDesktop);
    return () => {
      x.removeEventListener('change', checkIfDesktop);
    };
  }, []);

  useEffect(() => {
    if (notDeskTop) {
      setHeroImage(franchiseHeroImageMobile);
    } else {
      setHeroImage(franchiseHeroImageDesktop);
    }
  });

  const onWayPointEnter = index => {
    setActiveIndex(index);

    // get the element from the left rail and its position on the page
    const leftRailEle = document.getElementById(`articleList__item-${index}`);
    const offset = get(leftRailEle, 'offsetTop');

    // tell its parent scrolling div to scroll to it
    const leftRailScroll = document.getElementById(`articleList__inner`);
    leftRailScroll.scrollTo({
      top: offset - 116, // bc of the padding-top
      behavior: 'smooth'
    });
  };

  const goToTopOfList = () => {
    const distance =
      window.pageYOffset +
      document.querySelector('#scrollHere').getBoundingClientRect().top;
    window.scrollTo({ top: distance - 60, left: 0, behavior: 'smooth' });
  };

  // Initiate from Redux
  const { franchisePage, status } = props;

  // Handle Lazy Loading & Errors
  if (franchisePage.error || status === 404 || isEmpty(fullPackageObj)) {
    return (
      <section className="best-workplaces-for-innovators-page">
        <article className="best-workplaces-for-innovators__main--error-pg">
          <NotFound />
        </article>
      </section>
    );
  }

  if (franchisePage.isLoading) {
    return (
      <section className="best-workplaces-for-innovators-page">
        <article className="best-workplaces-for-innovators__main">
          <LoadingSpinner />
        </article>
      </section>
    );
  }

  let amazonTextStyles = {};
  let amazonHeaderStyles = {};

  if (isAmazon) {
    sectionBgColor = '#fff';
    headerColor = '#000';
    textColor = '#000';
    maxWidth = 940;
    borderBottom = '#ccc';
    amazonHeaderStyles = {
      fontWeight: '600'
    };
    amazonTextStyles = {
      fontSize: '17px',
      fontFamily: 'CentraNo2Book',
      lineHeight: '26px'
    };
  }

  return (
    <IbdLayout sublayout="amazon">
      {franchiseTitle && <h1 className="eyebrow">{franchiseTitle}</h1>}
      <div className="anniversaryGridLayout">
        <div className="anniversaryGridLayout__left">
          {notDeskTop ? (
            false
          ) : (
            <ArticleList
              articles={sections}
              name="ibd_anniversary"
              image="https://images.fastcompany.net/image/upload/v1631224496/fcweb/ibdx10-lockup_qgcanu.svg"
              activeIndex={activeIndex}
              noLogo={isAmazon}
            />
          )}
        </div>
        <div className="anniversaryGridLayout__right">
          <div id="anniversaryLander">
            <Section
              key="section-cover"
              name="ibd_anniversary"
              zIndex={1}
              cover={true}
              addClass="hero_section menuCover"
              noPadding={true}
              slug="scrollHere"
            >
              <div className={`section__hero ${isAmazon ? 'amazon' : ''}`}>
                <div className="hero__left">
                  <Image image="https://images.fastcompany.net/image/upload/v1631224496/fcweb/ibdx10-lockup_qgcanu.svg" />
                </div>
                <div className="hero__right">
                  <Image image={heroImage} />
                  {!isAmazon && (
                    <div className="introText">
                      <span
                        dangerouslySetInnerHTML={{ __html: franchiseBlurb }}
                      />
                    </div>
                  )}
                  <Text
                    centered
                    absolute
                    fontSize="30px"
                    fontFamily={`"CentraNo2Book", sans-serif`}
                  >
                    ↓
                  </Text>
                </div>
              </div>
            </Section>
            {sections.map((item, index) => {
              const regexFilter = item.sectionTitle.match(/([A-Z])\w+/g);
              const dropCap = get(regexFilter, '[0]', '').charAt(0);
              switch (item.sectionType) {
                case 'standard':
                  return (
                    <Section
                      key={`section-${item.sectionType}-${index}`}
                      name="ibd_anniversary"
                      bgColor={sectionBgColor}
                      maxWidth={maxWidth}
                      zIndex={0}
                      borderBottom={`1px solid ${borderBottom}`}
                      position={index}
                      onWayPointEnter={onWayPointEnter}
                      slug={slugify(item.sectionTitle)}
                    >
                      <div className="sectionHeader">
                        <a target="_blank" href={item.sectionUrl}>
                          <Text color={headerColor} as="h2" className="h2">
                            {item.sectionTitle}
                          </Text>
                        </a>
                        <Text color={textColor} as="h6" className="h6">
                          {item.sectionDeck}
                        </Text>
                      </div>
                      <Image image={item.sectionImage} width="831px" />
                      <div className="mtb-md">
                        <a
                          href={item.sectionUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            label="Read More"
                            rounded
                            font="normal normal normal 12px/14px CentraNo2"
                            letterSpacing="2.4px"
                          >
                            READ MORE
                          </Button>
                        </a>
                      </div>
                    </Section>
                  );

                case 'video':
                  return (
                    <Section
                      key={`section-${item.sectionType}-${index}`}
                      noPadding
                      margin="0 auto"
                      name="ibd_anniversary"
                      bgColor={sectionBgColor}
                      maxWidth={maxWidth}
                      borderBottom={`1px solid ${borderBottom}`}
                      bgVideo={item.sectionVideo}
                      addClass="section5"
                      position={index}
                      onWayPointEnter={onWayPointEnter}
                      slug={slugify(item.sectionTitle)}
                    >
                      <div className="sectionHeader">
                        <a target="_blank" href={item.sectionUrl}>
                          <Text color="#000000" as="h2" className="h2">
                            {item.sectionTitle}
                          </Text>
                        </a>
                        <Text color="#000000" as="h6" className="h6">
                          {item.sectionDeck}
                        </Text>
                      </div>
                      <div className="mtb-md">
                        <a
                          href={item.sectionUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            label="Read More"
                            rounded
                            font="normal normal normal 12px/14px CentraNo2"
                            letterSpacing="2.4px"
                          >
                            READ MORE
                          </Button>
                        </a>
                      </div>
                    </Section>
                  );

                case 'grid-right':
                  return (
                    <Section
                      name="ibd_anniversary"
                      bgColor={sectionBgColor}
                      maxWidth={maxWidth}
                      zIndex={0}
                      borderBottom={`1px solid ${borderBottom}`}
                      addClass="section3"
                      position={index}
                      onWayPointEnter={onWayPointEnter}
                      slug={slugify(item.sectionTitle)}
                    >
                      <Grid>
                        <Row>
                          <Col>
                            <div className="sectionHeader">
                              <a target="_blank" href={item.sectionUrl}>
                                <Text
                                  color={headerColor}
                                  as="h2"
                                  className="h2"
                                >
                                  {item.sectionTitle}
                                </Text>
                              </a>
                              <Text color={textColor} as="div" className="div">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.description
                                  }}
                                />
                              </Text>
                            </div>
                            <div className="mtb-md">
                              <a
                                href={item.sectionUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Button
                                  label="Read More"
                                  rounded
                                  font="normal normal normal 12px/14px CentraNo2"
                                  letterSpacing="2.4px"
                                >
                                  READ MORE
                                </Button>
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </Grid>
                    </Section>
                  );

                case 'grid-left':
                  return (
                    <Section
                      name="ibd_anniversary"
                      bgColor={sectionBgColor}
                      maxWidth={maxWidth}
                      zIndex={0}
                      borderBottom={`1px solid ${borderBottom}`}
                      addClass="section4"
                      position={index}
                      onWayPointEnter={onWayPointEnter}
                      slug={slugify(item.sectionTitle)}
                    >
                      <Grid>
                        {isAmazon &&
                          index === 0 && (
                            <div className="introText">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: franchiseBlurb
                                }}
                              />
                            </div>
                          )}
                        <Row addClass="amazon">
                          <Col>
                            {isAmazon &&
                              item.dropcap && (
                                <div className="dropCap">
                                  <p>{dropCap}</p>
                                </div>
                              )}
                            {!item.image_under_text && (
                              <Image
                                image={item.sectionImage}
                                width={item.image_width}
                              />
                            )}
                          </Col>
                          <Col>
                            <div className="sectionHeader">
                              {item.sectionUrl ? (
                                <a target="_blank" href={item.sectionUrl}>
                                  <Text
                                    {...amazonHeaderStyles}
                                    color={headerColor}
                                    as="h2"
                                    className="h2"
                                  >
                                    {item.sectionTitle}
                                  </Text>
                                </a>
                              ) : (
                                <Text
                                  {...amazonHeaderStyles}
                                  color={headerColor}
                                  as="h2"
                                  className="h2"
                                >
                                  {item.sectionTitle}
                                </Text>
                              )}
                              <Text
                                {...amazonTextStyles}
                                color={textColor}
                                as="div"
                                className="div"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.description
                                  }}
                                />
                              </Text>
                              {item.image_under_text && (
                                <Image
                                  image={item.sectionImage}
                                  width={item.image_width}
                                />
                              )}
                            </div>

                            {item.sectionUrl && (
                              <div className="mtb-md">
                                <a
                                  href={item.sectionUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button
                                    label="Read More"
                                    rounded
                                    font="normal normal normal 12px/14px CentraNo2"
                                    letterSpacing="2.4px"
                                  >
                                    READ MORE
                                  </Button>
                                </a>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Grid>
                    </Section>
                  );

                case 'article-left':
                  return (
                    <Section
                      name="ibd_anniversary"
                      borderBottom={`1px solid ${borderBottom}`}
                      addClass="articleLeftSection"
                      noPadding
                      // maxWidth={900}
                      position={index}
                      onWayPointEnter={onWayPointEnter}
                      slug={slugify(item.sectionTitle)}
                    >
                      <Grid>
                        <Row>
                          {/* {notMobile ? (
                            false
                          ) : ( */}
                          <Col noPadding>
                            <div
                              className="image-bgCover"
                              style={{
                                backgroundImage: `url(${item.sectionImage})`
                              }}
                            />
                          </Col>
                          {/* )} */}
                          <Col
                            noPadding
                            justify="center"
                            alignItems="center"
                            direction="column"
                          >
                            {/* {notMobile ? ( */}
                            <Row>
                              <Col noPadding>
                                <div
                                  className="image-bgCover"
                                  style={{
                                    backgroundImage: `url(${item.sectionImage})`
                                  }}
                                />
                              </Col>
                            </Row>
                            {/* ) : (
                              false
                            )} */}
                            <div className="inner-grid-padding">
                              <div className="sectionHeader">
                                <a target="_blank" href={item.sectionUrl}>
                                  <Text
                                    fontFamily="CentraNo2Book"
                                    color="#000000"
                                    as="h2"
                                    fontSize="45px"
                                    className="h2"
                                  >
                                    {item.sectionTitle}
                                  </Text>
                                </a>
                                <Text
                                  fontFamily="CentraNo2Book"
                                  color="#000000"
                                  as="h6"
                                  fontSize="16px"
                                  className="h6"
                                >
                                  {item.sectionDeck}
                                </Text>
                              </div>
                              <div className="mtb-md">
                                <a
                                  href={item.sectionUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button
                                    label="Read More"
                                    rounded
                                    font="normal normal normal 12px/14px CentraNo2"
                                    letterSpacing="2.4px"
                                  >
                                    READ MORE
                                  </Button>
                                </a>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Grid>
                    </Section>
                  );

                case 'article-right':
                  return (
                    <Section
                      name="ibd_anniversary"
                      borderBottom={`1px solid ${borderBottom}`}
                      addClass="articleRightSection"
                      noPadding
                      // maxWidth={900}
                      position={index}
                      onWayPointEnter={onWayPointEnter}
                      slug={slugify(item.sectionTitle)}
                    >
                      <Grid>
                        <Row>
                          <Col
                            noPadding
                            justify="center"
                            alignItems="center"
                            direction="column"
                          >
                            {/* {notMobile ? ( */}
                            <Row>
                              <Col noPadding>
                                <div
                                  className="image-bgCover"
                                  style={{
                                    backgroundImage: `url(${item.sectionImage})`
                                  }}
                                />
                              </Col>
                            </Row>
                            {/* ) : (
                              false
                            )} */}
                            <div className="inner-grid-padding">
                              <div className="sectionHeader">
                                <a target="_blank" href={item.sectionUrl}>
                                  <Text
                                    fontFamily="CentraNo2Book"
                                    color="#000000"
                                    as="h2"
                                    fontSize="45px"
                                    className="h2"
                                  >
                                    {item.sectionTitle}
                                  </Text>
                                </a>
                                <Text
                                  fontFamily="CentraNo2Book"
                                  color="#000000"
                                  as="h6"
                                  fontSize="16px"
                                  className="h6"
                                >
                                  {item.sectionDeck}
                                </Text>
                              </div>
                              <div className="mtb-md">
                                <a
                                  href={item.sectionUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button
                                    label="Read More"
                                    rounded
                                    font="normal normal normal 12px/14px CentraNo2"
                                    letterSpacing="2.4px"
                                  >
                                    READ MORE
                                  </Button>
                                </a>
                              </div>
                            </div>
                          </Col>
                          {/* {notMobile ? (
                            false
                          ) : ( */}
                          <Col noPadding>
                            <div
                              className="image-bgCover"
                              style={{
                                backgroundImage: `url(${item.sectionImage})`
                              }}
                            />
                          </Col>
                          {/* )} */}
                        </Row>
                      </Grid>
                    </Section>
                  );

                case 'feature-credits':
                  return (
                    <Section
                      key={`section-${item.sectionType}-${index}`}
                      name="ibd_anniversary"
                      bgColor={sectionBgColor}
                      maxWidth={maxWidth}
                      zIndex={0}
                      borderBottom={`1px solid ${borderBottom}`}
                      position={index}
                      onWayPointEnter={onWayPointEnter}
                      slug={slugify(item.sectionTitle)}
                    >
                      <Text
                        centered
                        color={textColor}
                        as="div"
                        className="div"
                        fontSize="16px"
                        fontFamily={`"MeretPro", sans-serif`}
                        fontStyle="italic"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.description
                          }}
                        />
                      </Text>
                    </Section>
                  );

                default:
                  return null;
              }
            })}
          </div>
          <Section
            addClass="backToTop"
            name="ibd_anniversary"
            bgColor="#FFFFFF"
            maxWidth={300}
          >
            <a
              href=""
              onClick={event => {
                event.preventDefault();
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <Text centered color="#000000">
                BACK TO TOP
              </Text>
            </a>
          </Section>
        </div>
      </div>

      <StickyBar
        slug={get(fullPackageData, 'slug', 'default')}
        location="lander"
        post={{
          id: 'amazon-unpacked',
          title: get(fullPackageData, 'acf.title', 'Fast Company')
        }}
        scroll={goToTopOfList}
      />

      <div className="post__article-top-ad-wrapper">
        <AdContainer type="break" />
      </div>
    </IbdLayout>
  );
};

// SSR Loading of data via Redux
AmazonLayout.loadData = store => loadData(store);

function mapStateToProps(state = {}) {
  return {
    status: state.status.code,
    franchisePage: get(state, 'franchisePage') || {
      error: true
    },
    config: state.config
  };
}

AmazonLayout.propTypes = {
  status: PropTypes.number.isRequired,
  franchisePage: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
  }).isRequired
};

export default withRouter(connect(mapStateToProps)(AmazonLayout));
