import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import assign from 'lodash/assign';
import RelatedPosts from '../../related_posts/related_posts';
import { imageSizesLookup } from '../../../../utils/cloudinary_asset_util';
import {
  isAdvertorial,
  isCustom,
  isPremium
} from '../../../../views/components/advertorial/advertorial';
import SiderailVideoModuleReactJW from '../../siderail_video_module/siderail_video_module_reactjw';
import { isCategory } from '../../../../utils/category_util';
import generateVideoCustomParams from '../../../../utils/seo_utils/video/video_post_custom_params_util';
import AdContainer from '../../ad/AdContainer';
import clsx from 'clsx';

const Sidebar = React.memo(
  ({
    post,
    fullPageAdvertorial,
    isVideoPost,
    config,
    posts,
    isFirstPost,
    related,
    isLongPost,
    totalAdCount
  }) => {
    const isLongPostAndNotVideo = isLongPost && !isVideoPost;
    const totalPromotions = isLongPostAndNotVideo ? totalAdCount : 1;
    const postTags = get(post, 'tags');
    const isPremiumArticle = isPremium(postTags);
    const hasPosterVideo = get(post, 'posterVideo.feedId');
    const isListPage = get(posts, 'length') > 1;
    const postReadTime = get(post, 'readTime');
    const useMicrosoftPlaylist =
      isCategory(get(post, 'categories', []), 'recommender') ||
      isCategory(get(post, 'categories', []), 'magazine');

    const hasExcludedTag = !!postTags.find(tag =>
      [
        'davos-dialogues',
        'cybersecurity',
        'mindfulness-at-work',
        '_advertorial_mic_summit',
        'financing-the-future'
      ].includes(tag.slug)
    );

    const showSidebarVideo =
      isFirstPost &&
      !hasPosterVideo &&
      !isVideoPost &&
      !isAdvertorial(postTags) &&
      !isListPage &&
      !hasExcludedTag;

    /**
     * VideoSidebar component that displays a video module and an ad container.
     * @returns {JSX.Element} JSX element containing the video module and ad container.
     */
    const VideoSidebar = () => (
      <>
        <div
          key={`sidebar-nonsticky-${post?.id}`}
          className="post__promotion post__promotion--non-sticky"
        >
          <AdContainer type="rail_sticky" page={isFirstPost ? 1 : 2} />
        </div>
        <div
          className="post__promotion"
          key={`post-ad-${post?.id}-1`}
          id={`post-ad-${post?.id}-1`}
        >
          <div className="post__promotion__inner--sticky">
            <div className="post__relative" key={`sidebar-video-${post?.id}`}>
              <SiderailVideoModuleReactJW
                config={config}
                type="rightrail"
                playerid="2fyG0pBE"
                playlistId={
                  useMicrosoftPlaylist
                    ? '9JiEM2iD' // Right Rail Playlist (Microsoft)
                    : 'dOytm2y2' // Right Rail Playlist Manual
                }
                pos="newmultiplayer"
                readTime={postReadTime}
                customparams={generateVideoCustomParams(post)}
              />
            </div>
            <AdContainer type="rail_sticky" page={isFirstPost ? 1 : 2} />
          </div>
        </div>
      </>
    );

    /**
     * A functional component that renders the sidebar for a post.
     * @param {number} index - The index of the sidebar.
     * @returns {JSX.Element} - The JSX element representing the sidebar.
     */
    // eslint-disable-next-line react/prop-types
    const GenericSidebar = ({ index = 0 }) => (
      <div
        key={`GenericSidebar-${post?.id}-${index}`}
        id={`post-ad-${post?.id}-${index}`}
        className={clsx('post__promotion', {
          'post__promotion--video': isVideoPost,
          'post__promotion--advertorial': fullPageAdvertorial
        })}
      >
        <div className="post__promotion__inner--sticky">
          <AdContainer type="rail_sticky" page={isFirstPost ? 1 : 2} />
          {index === 0 &&
          isLongPost &&
          !isVideoPost &&
          !postTags.map(tag => tag.name).includes('*oracle_advertorial') && // Exception for Oracle
          (isCustom(postTags) || isAdvertorial(postTags)) ? (
            <div className="post__relative">
              <RelatedPosts
                config={config}
                stories={related.map(article =>
                  assign(article, {
                    assetSizes: imageSizesLookup.column
                  })
                )}
                fullPageAdvertorial={fullPageAdvertorial}
              />
            </div>
          ) : null}
        </div>
      </div>
    );

    // If the article is premium, don't show the sidebar
    if (isPremiumArticle) {
      return null;
    }

    return (
      <div
        className={clsx('post__sidebar', {
          'post__sidebar--video': isVideoPost
        })}
      >
        {showSidebarVideo ? (
          <VideoSidebar />
        ) : (
          [...Array(totalPromotions)].map((_, index) => (
            <GenericSidebar index={index} />
          ))
        )}
      </div>
    );
  },
  // Only re-render if the post id changes
  (prevProps, nextProps) => prevProps?.post?.id === nextProps?.post?.id
);

Sidebar.defaultProps = {
  related: [],
  fullPageAdvertorial: false,
  isVideoPost: false,
  isLongPost: false,
  posts: [],
  totalAdCount: 2
};

Sidebar.propTypes = {
  config: PropTypes.shape({}).isRequired,
  post: PropTypes.shape({}).isRequired,
  isFirstPost: PropTypes.bool.isRequired,
  related: PropTypes.arrayOf(PropTypes.shape({})),
  fullPageAdvertorial: PropTypes.bool,
  isLongPost: PropTypes.bool,
  isVideoPost: PropTypes.bool,
  posts: PropTypes.arrayOf(PropTypes.shape({})),
  totalAdCount: PropTypes.number
};

export default Sidebar;
