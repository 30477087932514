import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import get from 'lodash/get';
import assign from 'lodash/assign';

import Arrow from '../arrow/arrow';
import Card from '../card/card';
import Slug from '../slug/slug';
import { imageSizesLookup } from './../../../utils/cloudinary_asset_util';

const prevArrow = (
  <div>
    <svg
      width="23"
      height="52"
      viewBox="0 0 23 52"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M22 51L2 26 22 1"
        strokeWidth="2"
        stroke="#CCC"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  </div>
);

const nextArrow = (
  <div>
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="23"
      height="52"
      viewBox="0 0 23 52"
    >
      <path
        d="M1 1l20 25L1 51"
        strokeWidth="2"
        stroke="#CCC"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  </div>
);

class RelatedSlider extends Component {
  constructor(props) {
    super();
    this.renderRelatedCard = this.renderRelatedCard.bind(this);
    this.generateClassNames = this.generateClassNames.bind(this);
    this.handleSlideChange = this.handleSlideChange.bind(this);
    this.slidesToScroll =
      get(props, 'responsive[1].settings.slidesToScroll') || 3;
    this.style = get(props, 'style', {});
    this.settings = {
      prevArrow: <Arrow markup={prevArrow} />,
      nextArrow: <Arrow markup={nextArrow} />,
      accessibility: true,
      slide: '.video-slide--no-border',
      infinite: false,
      responsive: props.responsive
        ? props.responsive
        : [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2.1,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 100000,
              settings: {
                slidesToShow: 3.3,
                slidesToScroll: this.slidesToScroll
              }
            }
          ]
    };

    const slideCount = get(props, 'related.length');
    this.maxNext = Math.floor(slideCount / this.slidesToScroll);
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state, react/no-unused-state
    this.setState({ settings: this.settings, paddlesToShow: [0, 1] });
  }

  generateClassNames() {
    let classnames = ['related-slider'];
    if (this.props.classNames) {
      classnames = classnames.concat(this.props.classNames);
    }

    if (!get(this.state, 'paddlesToShow[0]')) {
      classnames.push('related-slider--initial');
    }

    if (!get(this.state, 'paddlesToShow[1]')) {
      classnames.push('related-slider--end');
    }

    return classnames.join(' ');
  }

  handleSlideChange(index) {
    if (index === 0) {
      return this.setState({
        paddlesToShow: [0, 1]
      });
    }

    if (index / this.slidesToScroll >= this.maxNext) {
      return this.setState({
        paddlesToShow: [1, 0]
      });
    }

    if (
      !this.state.paddlesToShow.reduce((accum, currVal) => accum && currVal)
    ) {
      return this.setState({
        paddlesToShow: [1, 1]
      });
    }

    return true;
  }

  renderRelatedCard(relatedVid) {
    const { config } = this.props;
    const post = assign({}, relatedVid, {
      assetSizes: imageSizesLookup.column
    });

    const generateClassNames = () => {
      let classes = ['video-slide'];

      if (this.props.slideClassNames) {
        classes = [...classes, ...this.props.slideClassNames];
      }

      return classes.join(' ');
    };

    return (
      <div className={generateClassNames()} key={post.id}>
        <Card
          type="video"
          metadata={post}
          config={config}
          // eslint-disable-next-line react/prop-types
          sliderCardClickedCallBack={this.props.sliderCardClicked}
          noLink={get(this.props, 'noLink', false)}
          onClick={() => true}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={this.generateClassNames()} style={this.style}>
        {!get(this.props, 'noLink', false) && (
          <Slug
            slugName={this.props.title}
            className="related-slider"
            hasLink={true}
            linkTo={
              this.props.playlistId
                ? `videos/playlist/${this.props.slug}/${
                    this.props.playlistId
                  }/1`
                : ''
            }
          />
        )}
        <div>
          {this.state ? (
            <Slider
              {...get(this.state, 'settings', {})}
              afterChange={this.handleSlideChange}
            >
              {this.props.related.map(this.renderRelatedCard)}
            </Slider>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default RelatedSlider;

RelatedSlider.defaultProps = {
  title: 'Related',
  classNames: [],
  responsive: null,
  slideClassNames: '',
  playlistId: '',
  // slug is slugified version of
  // jwplaylist title
  slug: ''
};

RelatedSlider.propTypes = {
  config: PropTypes.shape({}).isRequired,
  related: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string,
  playlistId: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  responsive: PropTypes.arrayOf(PropTypes.shape({})),
  slideClassNames: PropTypes.string,
  slug: PropTypes.string
};
