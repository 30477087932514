import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Body from '../content/body';
import Sidebar from '../content/sidebar';
import withSecondScroll from './withSecondScroll';

const PostShort = props => {
  const { posts, isFirstPost, advertorial } = props;
  const hasNewsletter = index => {
    const news = get(props, 'match.url', '').indexOf('/news') > -1;

    if (news) {
      return isFirstPost && index === posts.length - 1;
    }

    return true;
  };

  return (
    <div className="post post--short">
      <div className="post__main">
        {posts.map((post, index) => (
          <Body
            {...props}
            key={post.id}
            post={post}
            hasNewsletter={hasNewsletter(index)}
            fullPageAdvertorial={advertorial}
          />
        ))}
      </div>
      <Sidebar
        {...props}
        post={get(posts, '[0]')}
        fullPageAdvertorial={advertorial}
      />
    </div>
  );
};

PostShort.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFirstPost: PropTypes.bool.isRequired,
  advertorial: PropTypes.bool.isRequired
};

export default withSecondScroll(PostShort);
