/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

// HOC for keeping track of when second scroll articles are rendered
const withSecondScroll = Component => props => {
  const { insert, setSecondScrollWasMounted } = props;
  const isSecondScroll =
    insert && typeof setSecondScrollWasMounted === 'function';

  useEffect(() => {
    if (isSecondScroll) {
      // Inform post_page that the second scroll article was rendered
      setSecondScrollWasMounted();
    }
  }, []);

  return <Component {...props} />;
};

export default withSecondScroll;
