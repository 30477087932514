import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Card from '../card/card';
import Slug from '../slug/slug';
import { trackGAEvent } from '../../../client/utils/third_party_trackers/ga';

export default function RelatedPosts(props) {
  if (!props) {
    return null;
  }

  const {
    config,
    stories,
    fullPageAdvertorial,
    slug,
    slugClassName,
    tiled,
    hideOnMobile,
    horizontal,
    reverse
  } = props;

  const topic =
    get(stories, '[0].categories[0].slug') ||
    get(stories, '[1].categories[0].slug') ||
    '';

  if (stories && get(stories, 'length') < 2) return null;

  return (
    <div
      className={`related-posts${hideOnMobile ? ' hide-on-mobile' : ''}${
        tiled ? ' tiled' : ''
      }`}
    >
      <Slug
        slugName={slug}
        className={`column ${slugClassName}`}
        linkTo={`/${topic}`}
      />

      <div className="cards">
        {stories.map((item, index) => (
          <Card
            horizontal={horizontal}
            reverse={reverse}
            config={config}
            type="small"
            metadata={item}
            key={item.id}
            fullPageAdvertorial={fullPageAdvertorial}
            noBottomBorder={tiled}
            vertical
            noBorder
            cardEvent={() => {
              let position = index;
              position += 1;
              trackGAEvent({
                eventCategory: 'related',
                eventAction: 'clicked',
                eventLabel: `algorithm=fc-custom,location=right-rail,position=${position}`
              });
            }}
          />
        ))}
      </div>
    </div>
  );
}

RelatedPosts.defaultProps = {
  fullPageAdvertorial: false,
  slug: 'Related Stories',
  slugClassName: null,
  tiled: false,
  hideOnMobile: false,
  horizontal: false,
  reverse: false
};

RelatedPosts.propTypes = {
  config: PropTypes.shape({}).isRequired,
  stories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fullPageAdvertorial: PropTypes.bool,
  slug: PropTypes.string,
  slugClassName: PropTypes.string,
  tiled: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
  horizontal: PropTypes.bool,
  reverse: PropTypes.bool
};
