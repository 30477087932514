import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Recaptcha from 'react-recaptcha';

import identityConfig from '../../../config/identity';

let scriptjs;

class ReCaptcha extends PureComponent {
  componentDidMount() {
    if (!window.IS_RECAPTCHA_SCRIPT_LOADED) {
      scriptjs = require('scriptjs'); // eslint-disable-line global-require
      scriptjs('https://www.google.com/recaptcha/api.js');
      window.IS_RECAPTCHA_SCRIPT_LOADED = true;
    }
  }

  render() {
    return (
      <Recaptcha
        sitekey={identityConfig.reCaptcha.public}
        render="explicit"
        verifyCallback={this.props.verifyCallback}
        onloadCallback={() => {}}
        elementID={this.props.id}
      />
    );
  }
}

ReCaptcha.propTypes = {
  verifyCallback: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

export default ReCaptcha;
